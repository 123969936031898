import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Hidden, Row, Visible } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    fetchCompetenceSuggestions,
    fetchSuggestions,
    nextCard,
    setSuggestionResponse,
} from '../store/actions/suggestions'
import { logoutUser, saveFirstLogin } from '../store/actions/auth'
import { translate, Translate } from 'react-i18nify'
import history from '../history'
import { ToastContainer } from 'react-toastr'

import Card from '../components/Card/Card'
import GridCol from '../components/GridCol/GridCol'
import GridColOffset from '../components/GridCol/GridColOffset'
import ModalForm from '../components/Modal/ModalForm'
import Actions from '../components/Actions/Actions'

import Modal from 'simple-react-modal'
import ApplyJobOffer from './ApplyJobOffer'
import { isSignedIn } from '../helpers'

class CardsPage extends Component {
    constructor(props) {
        super(props)

        this.onPositive = this.onPositive.bind(this)
        this.onNegative = this.onNegative.bind(this)

        this.onSuggestionError = this.onSuggestionError.bind(this)
        this.getCard = this.getCard.bind(this)
        this.getSuggestions = this.getSuggestions.bind(this)

        this.sendSuggestionResponse = this.sendSuggestionResponse.bind(this)

        this.fetch = this.fetch.bind(this)

        this.state = {
            shouldShowProfileCta: false,
            showFeedbackModal: false,
            feedback: {},
            isFetching: false,
            job_offer: null,
            showJobOffer: false,
            apply: false,
            suggestions: [],
            fetching: false,
            isFirstLogin: true,
        }

        this.toastContainer = React.createRef()
    }

    componentDidMount() {
        if (this.props.auth !== undefined && this.props.auth.isAuthenticated) {
            if (
                this.props.config.whitelabel &&
                !this.props.config.whitelabel.show_suggestion_page
            ) {
                return history.push(this.props.config.whitelabel.home_page)
            }

            let fetchCompetences = false
            let search = window.location.search
            let queryString = search.replace('?', '')
            if (queryString) {
                let query = this.parseQueryString(queryString)

                if (query.type && query.type === 'competence') {
                    fetchCompetences = true
                }
            }

            if (fetchCompetences) {
                this.getSuggestions()
            } else {
                this.setState(
                    {
                        fetching: true,
                    },
                    () => {
                        this.fetch()
                    },
                )
            }
        }
        if (!isSignedIn(this.props.auth)) {
            this.props.dispatch(logoutUser(this.props.auth, null))
        }
    }

    componentDidUpdate(previousProps) {
        if (
            this.props.config.whitelabel.show_suggestion_page !==
                previousProps.config.whitelabel.show_suggestion_page &&
            this.props.config.whitelabel &&
            !this.props.config.whitelabel.show_suggestion_page
        ) {
            return history.push(this.props.config.whitelabel.home_page)
        }
    }

    fetch(types = [], only = []) {
        console.log('[ Start Fetching ]')
        this.setState(
            {
                fetching: true,
                suggestions: [],
            },
            () => {
                this.props.dispatch(
                    fetchSuggestions(
                        this.props.auth,
                        (suggestions) => {
                            this.setState({
                                suggestions: suggestions,
                                fetching: false,
                            })

                            console.log('[ Found new suggestions ]', suggestions)
                        },
                        (status, response) => this.onSuggestionError(status, response),
                        [],
                        50,
                        this.props.suggestion,
                        true,
                        types,
                        only,
                    ),
                )
            },
        )
    }

    getSuggestions() {
        console.log('[ getting suggestions ]')
        this.props.dispatch(
            fetchCompetenceSuggestions(
                this.props.auth,
                () => {
                    this.setState({
                        suggestions: this.props.cards.suggestions,
                        fetching: false,
                    })
                },
                (status, resp) => {
                    this.onSuggestionError(status, resp)
                },
            ),
        )
    }

    parseQueryString(query) {
        let vars = query.split('&')
        let query_string = {}
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=')
            // If first entry with this name
            if (typeof query_string[pair[0]] === 'undefined') {
                query_string[pair[0]] = decodeURIComponent(pair[1])
                // If second entry with this name
            } else if (typeof query_string[pair[0]] === 'string') {
                let arr = [query_string[pair[0]], decodeURIComponent(pair[1])]
                query_string[pair[0]] = arr
                // If third or later entry with this name
            } else {
                query_string[pair[0]].push(decodeURIComponent(pair[1]))
            }
        }
        return query_string
    }

    /**
     * Send a positive response for the current card to the API and show the next card
     */
    onPositive(pullNew, hasLink = false) {
        let card = this.props.cards.card

        //If competence question was answered, show a notification that a competence was added
        if (
            card !== undefined &&
            card.suggestion_type !== undefined &&
            card.suggestion_type.code === 'competence_question'
        ) {
            this.showFeedbackModal(
                'Competentie toegevoegd',
                'We hebben een nieuwe competentie voor je toegevoegd op basis van je antwoorden op de vragen.',
            )
        }

        if (
            !hasLink &&
            card.hasOwnProperty('vacancy') &&
            card.vacancy !== null &&
            card.vacancy !== undefined
        ) {
            //Job offer. Show dialog
            this.setState({
                showJobOffer: true,
                job_offer: card.vacancy,
            })
        }

        this.sendSuggestionResponse(true, pullNew)
        this.props.dispatch(nextCard(this.props.auth))
    }

    /**
     * Send a negative response for the current card to the API and show the next card
     */
    onNegative(pullNew) {
        this.sendSuggestionResponse(false, pullNew)

        this.props.dispatch(nextCard(this.props.auth))
    }

    showFeedbackModal(title, message) {
        this.toastContainer.current.info(message, title, {
            timeOut: 10000,
            extendedTimeOut: 1000,
        })
    }

    /**
     * Make the response API call if the current card is not an empty object
     * @param card
     * @param confirmed
     */
    sendSuggestionResponse(confirmed, pullNew) {
        let card = this.state.suggestions[0]

        console.log('[ Send suggestion response ]', card, confirmed)
        if (card && Object.keys(card).length > 0) {
            let id = card.id

            console.log(
                '[ fetch ]',
                'Set response',
                this.state.suggestions.length,
                this.state.suggestions,
            )
            setSuggestionResponse(
                () => this.onSuggestionSuccess(card, pullNew),
                () => this.onSuggestionError(),
                id,
                confirmed,
                this.props.auth,
            )

            console.log('Response for card: ', card, confirmed)
        }
    }

    /**
     * Get a card view for a card object
     * @param card - the card to create a view for
     * @param pullNew - boolean whther to pull new requests after response
     * @returns {*}
     */
    getCard(card, pullNew = true) {
        console.log('[ getCard, pullNew ]', pullNew)
        if (
            card !== 'undefined' &&
            card != null &&
            Object.keys(card).length !== 0 &&
            card.constructor === Object
        ) {
            if (card.suggestion_type.view_file !== 'undefined') {
                var viewFile = card.suggestion_type.view_file

                console.log('[ CARD ]', card)
                var titleTop = this.getTitleTop(card) // suggestion > card > suggestion_type > code

                var className
                switch (viewFile) {
                    case 'bg_image':
                        return this.getDefaultCard(card, true, titleTop, pullNew)
                    case 'bg_image_small_text':
                        return this.getSmallTextCard(card, pullNew)
                    case 'logo':
                        return this.getLogoCard(card, titleTop, pullNew)
                    case 'list':
                        return this.getListCard(card, titleTop, pullNew)
                    default:
                        return this.getDefaultCard(card, false, titleTop, pullNew)
                }
            } else {
                console.log('Missing view file parameter.')
            }
        } else {
            return (
                <Card className="card--last">
                    <div className="front">
                        <img className={'card__image img-smiley-success'} />
                        <p>
                            <Translate value="cards.new_suggestions_explanation" />
                        </p>
                        <Link className="button--cta" to="profiel">
                            <Translate value="cards.back_to_profile" />
                        </Link>
                    </div>
                </Card>
            )
        }
    }

    /**
     * check if the card should have a top title (based on the card.suggestion_type.code), and return that title
     */
    getTitleTop(card) {
        if (card.suggestion_type && card.suggestion_type.name) {
            return card.suggestion_type.name
        }

        return undefined
    }

    /**
     * Get a basic card. Depending on the card object, this can contain a background image or be a blank card.
     * @param card
     * @param titleTop
     * @param pullNew
     * @returns {XML}
     */
    getSmallTextCard(card, titleTop = 'Competentie', pullNew) {
        var c = 'card--bg_img'

        return (
            <Card
                isInfo={card.id === null}
                className={c}
                onPositive={() => this.onPositive(pullNew)}
                onNegative={() => this.onNegative(pullNew)}
                titleTop={titleTop}
            >
                <div className="front">
                    <p className="card__text">{card.top}</p>

                    <h1 className="card__subject--small">{card.middle}</h1>

                    <p className="card__text">{card.bottom}</p>
                </div>

                {card.hasOwnProperty('more_info') &&
                    card.more_info !== null &&
                    card.more_info.length > 0 && (
                        <div className="back">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: '<div>' + card.more_info + '</div>',
                                }}
                            />
                        </div>
                    )}
            </Card>
        )
    }

    /**
     * Get a basic card. Depending on the card object, this can contain a background image or be a blank card.
     * @param card
     * @param bg_image
     * @param titleTop
     * @returns {XML}
     */
    getDefaultCard(card, bg_image = false, titleTop, pullNew) {
        var c = bg_image ? 'card--bg_img' : 'card'

        return (
            <Card
                isInfo={card.id === null}
                className={c}
                onPositive={() => this.onPositive(pullNew)}
                onNegative={() => this.onNegative(pullNew)}
                titleTop={titleTop}
            >
                <div className="front">
                    <p className="card__text">{card.top}</p>

                    <h1 className="card__subject">{card.middle}</h1>

                    <p className="card__text">{card.bottom}</p>
                </div>

                {card.hasOwnProperty('more_info') &&
                    card.more_info !== null &&
                    card.more_info.length > 0 && (
                        <div className="back">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: '<div>' + card.more_info + '</div>',
                                }}
                            />
                        </div>
                    )}
            </Card>
        )
    }

    /**
     * Get a card containing a company logo
     * @param card
     * @param titleTop
     * @returns {XML}
     */
    getLogoCard(card, titleTop, pullNew) {
        return (
            <Card
                className="card"
                onPositive={() => this.onPositive(pullNew)}
                onNegative={() => this.onNegative(pullNew)}
                titleTop={titleTop}
            >
                <div className="front">
                    <p className="card__text">{card.top}</p>

                    <img className="card__image" src={card.middle} />

                    <p className="card__text">{card.bottom}</p>
                </div>

                {card.hasOwnProperty('more_info') &&
                    card.more_info !== null &&
                    card.more_info.length > 0 && (
                        <div className="back">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: '<div>' + card.more_info + '</div>',
                                }}
                            />
                        </div>
                    )}
            </Card>
        )
    }

    /**
     * Create a card containing job offer information displayed in a list
     * @param card
     * @param titleTop
     * @returns {XML}
     */
    getListCard(card, titleTop, pullNew) {
        let jobOffer = card.vacancy

        let params = this.getJobParamsFromJobOffer(jobOffer)

        let stripped_more_info = this.stripImages(params.more_info)

        return (
            <Card
                className="card--list"
                onPositive={() => {
                    this.onPositive(pullNew, !!jobOffer.external_vacancy_url)
                }}
                href={jobOffer.external_vacancy_url}
                onNegative={() => this.onNegative(pullNew)}
                titleTop={titleTop}
            >
                <div className="front">
                    {params.name && params.name.length > 0 && (
                        <h1 className="card__header">{params.name}</h1>
                    )}
                    {params.organisation && params.organisation.length > 0 && (
                        /* pas fontsize aan aan de text lengte*/
                        <h2 className="card__subheader">{params.organisation}</h2>
                    )}

                    <Hidden xs>
                        <ul>
                            {params.working_area && params.working_area.length > 0 && (
                                <li>
                                    <i className="icons icon-direction" />
                                    {params.working_area}
                                </li>
                            )}
                            {params.location && params.location.length > 0 && (
                                <li>
                                    <i className="icons icon-location-pin" />
                                    {params.location}
                                </li>
                            )}
                            {params.hours && params.hours.length > 0 && (
                                <li>
                                    <i className="icons icon-clock" />
                                    {params.hours}
                                </li>
                            )}
                            {params.study_level && params.study_level.length > 0 && (
                                <li>
                                    <i className="icons icon-graduation" />
                                    {params.study_level}
                                </li>
                            )}
                            {params.working_time && params.working_time.length > 0 && (
                                <li>
                                    <i className="icons icon-book-open" />
                                    {params.working_time}
                                </li>
                            )}
                        </ul>
                    </Hidden>
                </div>
                {params.more_info && params.more_info.length > 0 && (
                    <div className="back">
                        {params.name && params.name.length > 0 && <h2>{params.name}</h2>}

                        <Visible xs>
                            <ul>
                                {params.working_area && params.working_area.length > 0 && (
                                    <li>
                                        <i className="icons icon-direction" />
                                        {params.working_area}
                                    </li>
                                )}
                                {params.location && params.location.length > 0 && (
                                    <li>
                                        <i className="icons icon-location-pin" />
                                        {params.location}
                                    </li>
                                )}
                                {params.hours && params.hours.length > 0 && (
                                    <li>
                                        <i className="icons icon-clock" />
                                        {params.hours}
                                    </li>
                                )}
                                {params.study_level && params.study_level.length > 0 && (
                                    <li>
                                        <i className="icons icon-graduation" />
                                        {params.study_level}
                                    </li>
                                )}
                                {params.working_time && params.working_time.length > 0 && (
                                    <li>
                                        <i className="icons icon-book-open" />
                                        {params.working_time}
                                    </li>
                                )}
                            </ul>
                        </Visible>

                        <div
                            dangerouslySetInnerHTML={{
                                __html: '<div>' + stripped_more_info + '</div>',
                            }}
                        />
                    </div>
                )}
            </Card>
        )
    }

    stripImages(htmlString) {
        return htmlString.replace(/((<img[\s\S][^>]*>)|(<\/[^^>]*img>))/, '')
    }

    /**
     * Extract the job offer params from the object
     * @param jobOffer
     * @returns {{}}
     */
    getJobParamsFromJobOffer(jobOffer) {
        var params = {}

        if (jobOffer !== undefined) {
            params.name = jobOffer.hasOwnProperty('name') ? jobOffer.name : null
            params.organisation = jobOffer.hasOwnProperty('organisation_name')
                ? jobOffer.organisation_name
                : null
            params.working_area = jobOffer.hasOwnProperty('job_working_area_name')
                ? jobOffer.job_working_area_name
                : null
            params.location = jobOffer.hasOwnProperty('organisation_location_name')
                ? jobOffer.organisation_location_name
                : null

            params.hours = this.getHoursString(jobOffer)
            params.study_level = jobOffer.hasOwnProperty('study_level_name')
                ? jobOffer.study_level_name
                : null
            params.working_time = jobOffer.hasOwnProperty('job_working_time_name')
                ? jobOffer.job_working_time_name
                : null

            let content = jobOffer.hasOwnProperty('content') ? jobOffer.content : null
            params.more_info = content ? jobOffer.content : null
        }

        return params
    }

    /**
     * Create a string based on the amount of hours for a certain job offer.
     * Can be null if the "hours_from" and "hours_till" fields are not set!
     * @param jobOffer
     * @returns {*}
     */
    getHoursString(jobOffer) {
        let from = jobOffer.hasOwnProperty('hours_from') ? jobOffer.hours_from : null
        let till = jobOffer.hasOwnProperty('hours_till') ? jobOffer.hours_till : null

        if (!till && !from) {
            return null
        } else if (!till || from === till) {
            return from + ' ' + translate('labels.hours')
        } else {
            return from + ' - ' + till + ' ' + translate('labels.hours')
        }
    }

    /**
     * Handle networking errors
     * @param status
     * @param error
     */
    onSuggestionError(status, error) {
        const hasPrivacyError =
            error.errors && error.errors.find((e) => e.message === 'privacy') !== undefined
        this.setState({
            fetching: false,
            invalidPrivacySettings: hasPrivacyError,
            errors: error.errors,
            suggestions: [],
        })
    }

    onSuggestionSuccess(card, pullNew) {
        if (card.id) {
            this.setState({
                suggestions: this.state.suggestions.filter(function (obj) {
                    return obj.id !== card.id
                }),
            })
        } else {
            this.setState({
                suggestions: this.state.suggestions.filter(function (obj) {
                    return obj.code !== card.code
                }),
            })
        }

        console.log('[ card saved, isFetching? and pullNew? ]', this.props.isFetching, pullNew)
        if (this.state.suggestions.length == 0 && !this.props.isFetching && pullNew) {
            console.log('[ Should fetch new ]', 'index')
            this.fetch()
        }
    }

    getFirstName() {
        if (this.props.auth !== undefined && this.props.auth.firstname !== undefined) {
            return ' ' + this.props.auth.firstname
        } else {
            return ''
        }
    }

    /**
     * Create a card intro string
     * @returns {XML}
     */
    getCardIntro() {
        if (
            (this.props.cards.card !== 'undefined' &&
                this.props.cards.card &&
                Object.keys(this.props.cards.card).length !== 0 &&
                this.props.cards.card.constructor === Object) ||
            this.props.cards.suggestions.length > 0
        ) {
            return (
                <p className="card__intro">
                    <Translate value="cards.hi" /> {this.getFirstName()},{' '}
                    <Translate value="cards.suggestions_found" />:
                </p>
            )
        } else {
            return (
                <p className="card__intro">
                    <Translate value="cards.all_suggestions_answered" />
                </p>
            )
        }
    }

    /**
     * Build an array of id's to exclude in the next API call as they are already in our list of suggestions
     * @param state
     * @param currentId - The index of the currently shown card
     * @returns {Array}
     */
    getExcludeIds(state, currentId) {
        var ids = []

        for (var i = currentId; i < state.suggestions.length; i++) {
            var suggestion = state.suggestions[i]
            ids.push(suggestion.id)
        }

        return ids
    }

    showSuccessMessage(title, message) {
        this.toastContainer.current.success(message, title, {
            timeOut: 10000,
            extendedTimeOut: 1000,
        })
    }

    render() {
        // injected by connect call
        const { dispatch, auth, cards, isFetching, isAuthenticated, errorMessage } = this.props

        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            {!this.state.fetching &&
                                !this.state.invalidPrivacySettings &&
                                this.state.suggestions.length <= 0 && (
                                    <div className="form--bg">
                                        <h1>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: translate('cards.no_suggestions'),
                                                }}
                                            />
                                        </h1>

                                        <img
                                            className={'form__image img-smiley-search'}
                                            width="163"
                                        />

                                        <p>
                                            <Translate value="cards.complete_profile" />
                                        </p>
                                        <Link className="button--alt inline-block" to="profiel">
                                            <Translate value="cards.button_complete_profile" />
                                        </Link>
                                    </div>
                                )}

                            {!this.state.fetching && this.state.invalidPrivacySettings && (
                                <div className="form--bg">
                                    <h1 className="is-loading-dots">
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: translate('cards.no_suggestions'),
                                            }}
                                        />
                                    </h1>

                                    <img className={'form__image img-smiley-search'} width="163" />

                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: translate('cards.privacy_settings'),
                                        }}
                                    />
                                    <Link className="button--alt inline-block" to="privacy">
                                        <Translate value="cards.button_privacy_settings" />
                                    </Link>
                                </div>
                            )}

                            {this.state.fetching && (
                                <div className="form--bg">
                                    <h1 className="is-loading-dots">
                                        <Translate value="cards.loading" />
                                    </h1>

                                    <img className={'form__image img-smiley-search'} width="163" />

                                    <p>
                                        <Translate value="cards.fetching_suggestions" />
                                    </p>
                                </div>
                            )}

                            {!this.state.fetching &&
                                this.state.suggestions &&
                                this.state.suggestions.length > 0 &&
                                this.getCardIntro()}

                            {!this.state.fetching &&
                                this.state.suggestions &&
                                this.state.suggestions.length > 0 &&
                                this.getCard(this.state.suggestions[0])}
                        </GridCol>
                    </Row>
                </Container>

                <ToastContainer ref={this.toastContainer} className="toast-bottom-right" />

                {this.state.showJobOffer && (
                    <ModalForm
                        title={this.state.job_offer.name}
                        show
                        onClose={() => {
                            this.setState({
                                showJobOffer: false,
                                job_offer: null,
                            })
                        }}
                    >
                        <p>
                            Wil je direct solliciteren op deze vacature? Je kunt altijd later
                            solliciteren via de vacature pagina.
                        </p>
                        <div className="margin-top">
                            <Actions>
                                <Link
                                    className="actions__action--cancel"
                                    onClick={() => {
                                        this.showFeedbackModal(
                                            this.state.job_offer.name,
                                            translate('cards.feedback_message_job'),
                                        )
                                        this.setState({
                                            showJobOffer: false,
                                            job_offer: null,
                                        })
                                    }}
                                    tabIndex="5"
                                >
                                    <Translate value="buttons.cancel" />
                                </Link>
                                <Link
                                    tabIndex="6"
                                    className="actions__action--save"
                                    onClick={() => {
                                        this.setState({
                                            showJobOffer: false,
                                            apply: true,
                                        })
                                    }}
                                >
                                    <Translate value="buttons.apply" />
                                </Link>
                            </Actions>
                        </div>
                    </ModalForm>
                )}

                {this.state.apply && (
                    <Modal
                        className="modal--vacancy"
                        containerClassName="modal__container"
                        closeOnOuterClick={true}
                        show={this.state.apply}
                        onClose={() =>
                            this.setState({
                                apply: false,
                                job_offer: null,
                            })
                        }
                    >
                        <ApplyJobOffer
                            dispatch={this.props.dispatch}
                            job_offer={this.state.job_offer.id}
                            onClose={(sent, saved) => {
                                this.setState({
                                    apply: false,
                                    job_offer: null,
                                })

                                if (sent) {
                                    this.showSuccessMessage(
                                        translate('vacancies.title_success'),
                                        translate('vacancies.message_success'),
                                    )
                                } else if (saved) {
                                    this.showSuccessMessage(
                                        translate('vacancies.title_saved'),
                                        translate('vacancies.message_saved'),
                                    )
                                }
                            }}
                        />
                    </Modal>
                )}
            </div>
        )
    }
}

CardsPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
}

function mapStateToProps(state) {
    const { test, auth, suggestions, user, config } = state
    const { isFetching, isAuthenticated, errorMessage } = auth

    return {
        auth,
        cards: suggestions,
        isFetching,
        isAuthenticated,
        errorMessage,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(CardsPage)
