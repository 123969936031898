import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { connect } from 'react-redux'
import JobExperiencePanel from './JobExperiencePanel'

class WorkExperiencePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            jobFunctionsPage: 0,
        }
    }

    render() {
        const { auth, user } = this.props

        return (
            <div>
                <Panel className="panel--multi">
                    <div id="workExperiencePanel">
                        <JobExperiencePanel showAlert={this.props.showAlert} />
                    </div>
                </Panel>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
        state,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(WorkExperiencePage)
