import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Actions from '../Actions/Actions'
import { connect } from 'react-redux'
import { translate, Translate } from 'react-i18nify'
import { addClass, getErrorFields, getErrorMessages, removeClass } from '../../helpers'
import Alert from '../Alert/Alert'
import Select from 'react-select'
import Async from 'react-select/async'
import 'react-dates/lib/css/_datepicker.css'
import newCallApi from '../../store/actions/api'
import DropdownDiplomaOption from '../DropdownMenu/DropdownDiplomaOption'

let map

class FormDiplomas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            diploma_id: null,
            specific_diplomas: [],
            school_id: null,
            loadingDiplomas: false,
            errors: [],
        }
    }

    getClassNames(item) {
        if (item != null && item != '' && item.length > 0) {
            return 'form__input--text has-value'
        } else {
            return 'form__input--text'
        }
    }

    getGenericDiplomaList(query, cb) {
        let endpoint = this.props.config.API_DIPLOMAS_LIST
        endpoint += query ? '?q=' + query : ''
        endpoint += '&school_id=' + this.state.school_id.value

        newCallApi('GET', endpoint)
            .then((diplomas) => {
                let currentDiplomas = []
                this.props.diplomas.map((diploma) => currentDiplomas.push(diploma.diploma_id))

                let arr = []
                ;(diplomas.data || diplomas).map((diploma) => {
                    if (currentDiplomas.indexOf(diploma.diploma_id) < 0) {
                        arr.push({
                            value: diploma.diploma_id,
                            label: diploma.title,
                            validity_ranges: diploma.validity_ranges,
                        })
                    }
                })
                cb(arr)
            })
            .catch(() => {})
    }

    updateSchool(school_id) {
        this.setState({ school_id })
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)

        if (changeClass) {
            if (value.target.value.length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    cancel() {
        this.state = {
            diploma_id: null,
            school_id: null,
            loadingDiplomas: false,
            errors: [],
        }

        this.props.onCancel()
    }

    save() {
        if (!this.props.userId || !this.state.diploma_id || !this.state.diploma_id.value)
            return this.setState({ errors: [translate('diplomas.select_one')] })
        this.setState({ errors: [] })
        this.setState({ loading: true })
        if (typeof this.props.onSaveStart === 'function') this.props.onSaveStart()

        newCallApi('POST', this.props.config.API_DIPLOMAS.replace('{account}', this.props.userId), {
            school_id: this.state.school_id.value,
            id: this.state.diploma_id.value,
        })
            .then(() => this.onSuccess())
            .catch((error) => this.onError(error.status, error))
            .then(() => this.setState({ loading: false }))
    }

    onError(status, object) {
        if (typeof this.props.onSaveEnd === 'function') this.props.onSaveEnd()
        this.setState(
            {
                errors: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    getClassNames(item, field) {
        let c = 'form__input--text'

        if (item != null && item != '' && item.length > 0) {
            c += ' has-value'
        }

        return c
    }

    onSuccess() {
        this.state = {
            diploma_id: null,
            school_id: null,
            loadingDiplomas: false,
            errors: [],
        }
        this.props.onSave()
        if (typeof this.props.onSaveEnd === 'function') this.props.onSaveEnd()
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.loading) {
            c += ' is-loading'
        }

        return c
    }

    render() {
        const { className } = this.props

        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        e.preventDefault()
                    }
                }}
            >
                <fieldset className="form__fieldset">
                    {this.state.errors && this.state.errors.length > 0 && (
                        <Alert type="alert--error alert--simple">
                            <ul>
                                {this.state.errors.map(function (value) {
                                    return <li key={value}>{value}</li>
                                })}
                            </ul>
                        </Alert>
                    )}

                    <div className="panel--diplomas form">
                        <Select
                            className="select--alt-black"
                            classNamePrefix="select"
                            id="school_select"
                            onChange={(school_id) => this.updateSchool(school_id)}
                            value={this.state.school_id}
                            clearable={false}
                            options={
                                this.props.user.schools
                                    ? this.props.user.schools.list.map((s) => ({
                                          value: s.brinnumber,
                                          label: s.name,
                                      }))
                                    : []
                            }
                            placeholder={translate('diplomas.hint_search_school_placeholder')}
                            noOptionsMessage={() => translate('diplomas.no_schools_found')}
                            isSearchable={true}
                        />
                    </div>
                    {this.state.school_id && (
                        <div className="panel--diplomas form">
                            <Async
                                className="select--alt-black"
                                classNamePrefix="select"
                                loadOptions={this.getGenericDiplomaList.bind(this)}
                                onChange={(diploma_id) => this.setState({ diploma_id })}
                                value={this.state.diploma_id}
                                isSearchable={true}
                                clearable={false}
                                loadingMessage={() => translate('texts.hint_loading')}
                                placeholder={translate('diplomas.hint_search_diploma_placeholder')}
                                noOptionsMessage={() => translate('diplomas.no_diplomas_found')}
                                components={{ Option: DropdownDiplomaOption }}
                            />
                        </div>
                    )}
                </fieldset>
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="4"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={this.getSaveClasses()}
                        onClick={() => this.save()}
                        tabIndex="5"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

FormDiplomas.propTypes = {
    className: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    edit: PropTypes.object,
    userId: PropTypes.number.isRequired,
    diplomas: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormDiplomas)
