import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Router } from 'react-router-dom'

import { hotjar } from 'react-hotjar'
import { setTranslations, setLocale } from 'react-i18nify'
import poTranslationsNLZorgselect from './locales/cache/nl--zorgselect.js'
import poTranslationsNLVota from './locales/cache/nl--vota.js'
import poTranslationsNLVindwerk from './locales/cache/nl--vindwerk.js'

import { persistor, store } from './store/index'

import history from './history'
import App from './App'

import 'react-dates/initialize'

require('./styles/style.scss')

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
    console.log = function () {}
    console.warn = function () {}
    console.error = function () {}
    hotjar.initialize(932981, 6)
}

setTranslations({
    nl: poTranslationsNLZorgselect,
    nl__zorgselect: poTranslationsNLZorgselect,
    nl__vota: poTranslationsNLVota,
    nl__vindwerk: poTranslationsNLVindwerk,
})
setLocale('nl__zorgselect')

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <App />
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
)
