import {
    CONFIG_ERROR,
    CONFIG_RECEIVED,
    CONFIG_REQUESTED,
    CONFIG_SUBLABEL,
    CONFIG_SUBLABEL_RESTORE,
    PATH_PROFILE,
} from '../../constants/Constants'
import { checkThemeColorChange } from '../../helpers'

export function config(
    state = {
        isFetching: false,
        whitelabel: {
            name: '',
            title: '',
            ga_property_id: '',
            flexapp: { used: false, name: '', url: '' },
            favicon: '',
            theme: {},
            profile_pages: [],
            sub_labels: [],
            show_suggestion_page: false,
            home_page: PATH_PROFILE,
            vacancy_map_city: null,
            terms_and_conditions: '',
            privacy_and_terms: '',
            show_roadmap: false,
            privacy_external: [],
            show_topics: false,
            show_school_subjects: false,
            form_profile_extra_info: [],
            open_badges_iframe_url: '',
            show_profile_export: false,
        },
        backup_whitelabel: {},
        API_BASE: '',
        BASE_URL: '',
        PARENT_BASE_URL: '',
        API_LOGIN: '',
        API_LOGOUT: '',
        API_SESSION: '',
        API_SSO: '',
        API_REGISTER: '',
        API_SHAREES: '',
        API_SHARING: '',
        API_ACTIVATE: '',
        API_FORGOT_PASSWORD: '',
        API_RESET_PASSWORD: '',
        API_SUGGESTIONS: '',
        API_DOCUMENT_AND_TYPES: '',
        API_ACCOUNT_STATUS: '',
        API_PROFILE: '',
        API_ACCOUNT_TYPES: '',
        API_RENEW_TOKEN: '',
        API_APPLICATIONS: '',
        API_APPLICATION: '',
        API_EVALUATIONS: '',
        API_EVALUATION_DESCRIPTION: '',
        API_EVALUATION: '',
        API_WORKING_AREAS: '',
        API_INDUSTRIES: '',
        API_STUDIES: '',
        API_STUDY_LEVELS: '',
        API_APPLICATION_FILES: '',
        API_PICTURE: '',
        API_BIG_CHECK: '',
        API_ROLES: '',
        API_PUBLIC_PROFILE: '',
        API_ORGANIZATIONS: '',
        API_FLEXAPP_ORGANIZATIONS: '',
        API_COMPETENCES: '',
        API_TOPICS: '',
        API_JOBS: '',
        API_VACANCIES: '',
        API_CITIES: '',
        API_DOCUMENTS: '',
        API_PRIVACY: '',
        API_VACANCY_REACTIONS: '',
        API_DOWNLOAD_EXPORT: '',
        API_EMAIL_EXPORT: '',
        API_RESUMES: '',
        API_SUBSCRIPTION: '',
        DOCUMENT_TYPES: '',
        ACCOUNT_TYPES: '',
        API_ACCOUNT_FIELDS: '',
        API_CHANGE_PASSWORD: '',
        API_DIPLOMAS: '',
        API_DIPLOMAS_LIST: '',
        API_EELLOO_TESTS: '',
        API_EELLOO_TEST_SSO: '',
        API_DOWNLOAD_EELLOO_TEST: '',
        API_SHARE_EELLOO_TEST: '',
        API_EMREX: '',
        API_STUDY_PROGRAM: '',
        API_FLEX_EMPLOYERS: '',
        API_GET_FIELDS_OF_INTEREST: '',
        API_EVENTS: '',
        API_TRAJECTORIES: '',
        API_TRAJECTORY_NOTES: '',
        API_TRAJECTORY_NOTE_COMMENT: '',
        API_FLEX_JOB_FUNCTIONS: '',
        API_SCHOOLS: '',
        API_SCHOOL_STUDY_PROGRAMS: '',
        API_SCHOOL_STUDY_PROGRAM_DETAILS: '',
        API_SCHOOL_SUBJECTS: '',
        API_SCHOOL_SUBJECT_LEVELS: '',
        API_EXPERTISES: '',
    },
    action,
) {
    switch (action.type) {
        case CONFIG_REQUESTED:
            return Object.assign({}, state, {
                isFetching: action.fetching,
            })
        case CONFIG_RECEIVED:
            let API_BASE = action.config.api_base
            let API_LOGIN = API_BASE + '/auth/login'
            let API_LOGOUT = API_BASE + '/auth/logout'
            let API_SESSION = API_BASE + '/auth/session'
            let API_SSO = API_BASE + '/auth/sso'
            let API_REGISTER = API_BASE + '/auth/register'
            let API_CONFIGURE_2FA = API_BASE + '/auth/2fa/configure'
            let API_ENABLE_2FA = API_BASE + '/auth/2fa/enable'
            let API_2FA = API_BASE + '/auth/2fa'
            let API_ACTIVATE = API_BASE + '/auth/activate' //zorgselect.nl/api/accounts/session
            let API_FORGOT_PASSWORD = API_BASE + '/auth/request-reset'
            let API_RESET_PASSWORD = API_BASE + '/auth/reset'
            let API_SUGGESTIONS = API_BASE + '/suggestions'
            let API_ACCOUNT_STATUS = API_BASE + '/account/status'
            let API_PROFILE = API_BASE + '/account'
            let API_ACCOUNT_TYPES = API_BASE + '/account-types'
            let API_RENEW_TOKEN = API_BASE + '/auth/refresh'
            let API_WORKING_AREAS = API_BASE + '/working-areas'
            let API_INDUSTRIES = API_BASE + '/industries'
            let API_STUDIES = API_BASE + '/studies'
            let API_STUDY_LEVELS = API_BASE + '/study-levels'
            let API_PICTURE = API_BASE + '/account/{account}/profilepicture'
            let API_PUBLIC_PROFILE = API_BASE + '/account/{account}'
            let API_ROLES = API_BASE + '/competence-roles'
            let API_ORGANIZATIONS = API_BASE + '/organisations'
            let API_FLEXAPP_ORGANIZATIONS = API_BASE + '/flexapp/organisations'
            let API_FLEXAPP_ORGANIZATIONS_PUBLIC = API_BASE + '/flexapp/organisations/public'

            let API_COMPETENCES = API_BASE + '/competences/suggested'
            let API_COMPETENCES_CLEAN = API_BASE + '/competences'

            let API_TOPICS = API_BASE + '/topics'
            let API_JOBS = API_BASE + '/job-functions'
            let API_VACANCIES = API_BASE + '/vacancies'
            let API_BIG_CHECK = API_BASE + '/big/check'
            let API_CITIES = API_BASE + '/cities'
            let API_DOCUMENTS = API_BASE + '/account/{account}/documents'
            let API_APPLICATIONS = API_BASE + '/clients/{account}/applications'
            let API_APPLICATION_FILES =
                API_BASE + '/clients/{account}/applications/{application_id}/files'
            let API_APPLICATION = API_BASE + '/clients/{account}/applications/{application_id}'
            let API_DIPLOMAS = API_BASE + '/account/{account}/diplomas'
            let API_DOCUMENT_AND_TYPES = API_BASE + '/account/{account}/document-types'
            let API_SHARING = API_BASE + '/account/{account}/sharing'
            let API_SHAREES = API_BASE + '/sharees'
            let API_EMAIL_SETTINGS = API_BASE + '/account/{account}/email-settings'
            let API_PRIVACY = API_BASE + '/account/{account}/privacy'
            let API_EVALUATIONS =
                API_BASE +
                '/account/{account}/diplomas/{diploma}/work-processes/{work_process}/evaluations'
            let API_EVALUATION_DESCRIPTION =
                API_BASE +
                '/account/{account}/diplomas/{diploma}/work-processes/{work_process}/evaluations/{evaluation}/work-process-description'
            let API_EVALUATION =
                API_BASE +
                '/account/{account}/diplomas/{diploma}/work-processes/{work_process}/evaluations/{evaluation}'
            let API_VACANCY_REACTIONS = API_BASE + '/vacancy-reactions'
            let API_DOWNLOAD_EXPORT = API_BASE + '/export/zip'
            let API_EMAIL_EXPORT = API_BASE + '/export/zip/email'
            let API_RESUMES = API_BASE + '/resumes'
            let API_SUBSCRIPTION = API_BASE + '/roadmap/subscriptions'
            let DOCUMENT_TYPES = API_BASE + '/account-types/{type}/documents'
            let ACCOUNT_TYPES = API_BASE + '/account-types'
            let API_ACCOUNT_FIELDS = API_BASE + '/{type}/account-fields'
            let API_CHANGE_PASSWORD = API_BASE + '/auth/changepassword'
            let API_DIPLOMAS_LIST = API_BASE + '/diplomas'
            let API_EELLOO_TESTS = API_BASE + '/account/{account}/account_tests/eelloo'
            let API_EELLOO_TEST_SSO =
                API_BASE + '/account/{account}/account_tests/eelloo/{accountTest}/sso'
            let API_DOWNLOAD_EELLOO_TEST = API_BASE + '/eelloo/{accountTest}/all-results'
            let API_SHARE_EELLOO_TEST = API_BASE + '/account/tests/{accountTestId}'

            let API_EMREX = API_BASE + '/emrex/request'
            let API_STUDY_PROGRAM = API_BASE + '/sbb/study-programs'
            let API_FLEX_EMPLOYERS = API_BASE + '/flexapp/organisations'
            let API_GET_FIELDS_OF_INTEREST = API_BASE + '/interests'
            let API_EVENTS = API_BASE + '/events'
            let API_FLEX_JOB_FUNCTIONS = API_BASE + '/flexapp/functions'
            let API_SCHOOLS = API_BASE + '/schools'
            let API_SCHOOL_DIPLOMAS = API_BASE + '/schools/{school}/diplomas'

            let API_TRAJECTORIES = API_BASE + '/account/{account_id}/trajectories/'
            let API_TRAJECTORY_NOTES = API_BASE + '/account/{account_id}/trajectories/notes'
            let API_TRAJECTORY_APPLICATIONS = API_BASE + '/clients/{account_id}/applications'
            let API_TRAJECTORY_NOTE_COMMENT = API_BASE + '/trajectories/notes/{note_id}/comments'

            let API_SCHOOL_SUBJECTS = API_BASE + '/school-subjects'
            let API_SCHOOL_SUBJECT_LEVELS = API_BASE + '/school-subject-levels'
            let API_EXPERTISES = API_BASE + '/expertises'

            return Object.assign({}, state, {
                isFetching: false,
                whitelabel: action.config.whitelabel,
                backup_whitelabel: action.config.whitelabel,
                API_BASE: API_BASE,
                BASE_URL: action.config.base_url,
                PARENT_BASE_URL: action.config.parent_base_url,
                API_LOGIN: API_LOGIN,
                API_LOGOUT: API_LOGOUT,
                API_SSO: API_SSO,
                API_SESSION: API_SESSION,
                API_REGISTER: API_REGISTER,
                API_ACTIVATE: API_ACTIVATE,
                API_FORGOT_PASSWORD: API_FORGOT_PASSWORD,
                API_RESET_PASSWORD: API_RESET_PASSWORD,
                API_SUGGESTIONS: API_SUGGESTIONS,
                API_ACCOUNT_STATUS: API_ACCOUNT_STATUS,
                API_PROFILE: API_PROFILE,
                API_ACCOUNT_TYPES: API_ACCOUNT_TYPES,
                API_RENEW_TOKEN: API_RENEW_TOKEN,
                API_WORKING_AREAS: API_WORKING_AREAS,
                API_INDUSTRIES: API_INDUSTRIES,
                API_STUDIES: API_STUDIES,
                API_STUDY_LEVELS: API_STUDY_LEVELS,
                API_PICTURE: API_PICTURE,
                API_ROLES: API_ROLES,
                API_ORGANIZATIONS: API_ORGANIZATIONS,
                API_FLEXAPP_ORGANIZATIONS: API_FLEXAPP_ORGANIZATIONS,
                API_COMPETENCES: API_COMPETENCES,
                API_COMPETENCES_CLEAN,
                API_TOPICS: API_TOPICS,
                API_EVALUATIONS: API_EVALUATIONS,
                API_EVALUATION_DESCRIPTION: API_EVALUATION_DESCRIPTION,
                API_EVALUATION: API_EVALUATION,
                API_JOBS: API_JOBS,
                API_BIG_CHECK: API_BIG_CHECK,
                API_VACANCIES: API_VACANCIES,
                API_PUBLIC_PROFILE: API_PUBLIC_PROFILE,
                API_CITIES: API_CITIES,
                API_DOCUMENTS: API_DOCUMENTS,
                API_EMAIL_SETTINGS: API_EMAIL_SETTINGS,
                API_PRIVACY: API_PRIVACY,
                API_VACANCY_REACTIONS: API_VACANCY_REACTIONS,
                API_DOWNLOAD_EXPORT: API_DOWNLOAD_EXPORT,
                API_EMAIL_EXPORT: API_EMAIL_EXPORT,
                API_RESUMES: API_RESUMES,
                API_APPLICATION_FILES: API_APPLICATION_FILES,
                API_SUBSCRIPTION: API_SUBSCRIPTION,
                DOCUMENT_TYPES: DOCUMENT_TYPES,
                ACCOUNT_TYPES: ACCOUNT_TYPES,
                API_ACCOUNT_FIELDS: API_ACCOUNT_FIELDS,
                API_CHANGE_PASSWORD: API_CHANGE_PASSWORD,
                API_CONFIGURE_2FA: API_CONFIGURE_2FA,
                API_DOCUMENT_AND_TYPES: API_DOCUMENT_AND_TYPES,
                API_ENABLE_2FA: API_ENABLE_2FA,
                API_SHARING: API_SHARING,
                API_SHAREES: API_SHAREES,
                API_2FA: API_2FA,
                API_DIPLOMAS: API_DIPLOMAS,
                API_APPLICATIONS: API_APPLICATIONS,
                API_APPLICATION: API_APPLICATION,
                API_DIPLOMAS_LIST: API_DIPLOMAS_LIST,
                API_EELLOO_TESTS: API_EELLOO_TESTS,
                API_EELLOO_TEST_SSO: API_EELLOO_TEST_SSO,
                API_DOWNLOAD_EELLOO_TEST: API_DOWNLOAD_EELLOO_TEST,
                API_SHARE_EELLOO_TEST: API_SHARE_EELLOO_TEST,
                API_EMREX: API_EMREX,
                API_STUDY_PROGRAM: API_STUDY_PROGRAM,
                API_FLEX_EMPLOYERS: API_FLEX_EMPLOYERS,
                API_GET_FIELDS_OF_INTEREST: API_GET_FIELDS_OF_INTEREST,
                API_EVENTS: API_EVENTS,
                API_FLEX_JOB_FUNCTIONS: API_FLEX_JOB_FUNCTIONS,
                API_FLEXAPP_ORGANIZATIONS_PUBLIC,
                API_SCHOOLS: API_SCHOOLS,
                API_SCHOOL_DIPLOMAS: API_SCHOOL_DIPLOMAS,
                API_TRAJECTORIES: API_TRAJECTORIES,
                API_TRAJECTORY_APPLICATIONS: API_TRAJECTORY_APPLICATIONS,
                API_TRAJECTORY_NOTES: API_TRAJECTORY_NOTES,
                API_TRAJECTORY_NOTE_COMMENT: API_TRAJECTORY_NOTE_COMMENT,
                API_SCHOOL_SUBJECTS: API_SCHOOL_SUBJECTS,
                API_SCHOOL_SUBJECT_LEVELS: API_SCHOOL_SUBJECT_LEVELS,
                API_EXPERTISES: API_EXPERTISES,
            })
        case CONFIG_ERROR:
            return Object.assign({}, state, {
                isFetching: action.fetching,
            })
        case CONFIG_SUBLABEL:
            return Object.assign({}, state, {
                backup_whitelabel: state.whitelabel,
                whitelabel: action.whitelabel,
            })
        case CONFIG_SUBLABEL_RESTORE:
            checkThemeColorChange(state.backup_whitelabel, [])
            return Object.assign({}, state, {
                whitelabel: state.backup_whitelabel,
            })
        default:
            return state
    }
}
